import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChargerDtoV2 } from './dto/charger.dto';
import { ChargerStickyFooter } from './charger-sticky-footer.component';
import { Image } from '../ui-components/image.component';
import warningIcon from '../../assets/icons/error_warning_circle.svg';
import chargerEvIcon from '../../assets/icons/charger_ev.svg';
import boltIcon from '../../assets/icons/boltIcon.svg';
import refreshIcon from '../../assets/icons/refresh.svg';
import checkDisplayIcon from '../../assets/icons/check_display.svg';
import { useTranslation } from 'react-i18next';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useSelector } from 'react-redux';
import HaveQuestions from '../login/have-questions.component';
import { useCallback } from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '40px 24px',
    height: 'calc(100vh - 50px)',
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    '& .header': {
      color: '#202223',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '32px',
    },
    '& .desc': {
      color: '#4E5968',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
    },
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .header': {
      color: '#202223',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '28px',
    },
  },
  stepWrapper: {
    display: 'flex',
    padding: '16px 12px',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '8px',
    background: '#F2F4F6',
  },
  stepContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& .header': {
      color: '#202223',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'left',
    },
    '& .desc': {
      color: '#4E5968',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'left',
    },
  },
  helpAndSupport: {},
}));

interface StepCard {
  icon: string;
  header: string;
  description: string;
}

const SINGLE_PORT_CARDS: StepCard[] = [
  {
    icon: chargerEvIcon,
    header: 'instruction_step_1_header',
    description: 'instruction_step_1_desc',
  },
  {
    icon: refreshIcon,
    header: 'instruction_start_over_header',
    description: 'instruction_start_over_desc',
  },
];
const MULTI_PORT_CARDS: StepCard[] = [
  {
    icon: chargerEvIcon,
    header: 'instruction_step_1_header',
    description: 'instruction_step_1_desc',
  },
  {
    icon: boltIcon,
    header: 'instruction_step_3_header',
    description: 'instruction_step_3_desc',
  },
  {
    icon: checkDisplayIcon,
    header: 'instruction_check_charger_display_header',
    description: 'instruction_check_charger_display_desc',
  },
  {
    icon: refreshIcon,
    header: 'instruction_start_over_header',
    description: 'instruction_start_over_desc',
  },
];

interface ChargerSessionTimeoutProps {
  charger: ChargerDtoV2;
  selectedConnectorId: number | null;
  onRetry: () => void;
  onCancel: () => void;
}

export const ChargerSessionTimeout = ({
  charger,
  selectedConnectorId,
  onRetry,
  onCancel,
}: ChargerSessionTimeoutProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useSelector(getTheme);

  const renderStep = useCallback(
    (icon: any, header: string, description = null) => {
      return (
        <div className={classes.stepWrapper} key={header}>
          <Image
            src={icon}
            style={{
              borderRadius: '50%',
              background: theme.brand_2 || '#18A0D7',
              width: '22px',
              height: '22px',
              padding: '2px',
            }}
          />
          <div className={classes.stepContent}>
            <Typography className='header'>{t(header)}</Typography>
            {description && (
              <Typography className='desc'>{t(description)}</Typography>
            )}
          </div>
        </div>
      );
    }, [classes, t, theme]);

  const renderRetryCards = useCallback(
    (isSinglePort: boolean) => {
      const retryCards = isSinglePort ? SINGLE_PORT_CARDS : MULTI_PORT_CARDS;

      return (
        <div className={classes.contentSection}>
          <Typography
            className='header'
            style={{ width: '300px', paddingLeft: '38px' }}
          >
            {t('session_timeout_instruction_steps')}
          </Typography>
          {retryCards.map((card) => (
            renderStep(card.icon, card.header, card.description)
          ))}
        </div>
      );
    }, [classes, t, theme]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.headerSection}>
          <Image src={warningIcon} style={{ width: '64px', height: '64px' }} />
          <Typography className='header'>
            {t('session_timeout_label')}
          </Typography>
          <Typography className='desc'>{t('session_timeout_desc')}</Typography>
        </div>
        {renderRetryCards(charger.ports.length === 1)}
        <div className={classes.helpAndSupport}>
          <HaveQuestions
            chargerDetail={charger}
            style={{ padding: '0px', backgroundColor: 'transparent' }}
          />
        </div>
      </div>
      <ChargerStickyFooter
        hasRetryBtn
        onRetry={onRetry}
        onCancel={onCancel}
        charger={charger}
        selectedConnectorId={selectedConnectorId}
      />
    </>
  );
};
