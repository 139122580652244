import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import pluginCharger from '../../assets/images/charger/charger-time-to-plugin.svg';
import tipsIcon from '../../assets/icons/tips.svg';
import { useTranslation } from 'react-i18next';
import { getTheme } from '../../stores/selectors/theme.selector';
import { ChargerQuestionHelpDropdown } from './charger-question-help-dropdown.component';
import { ChargerStickyFooter } from './charger-sticky-footer.component';
import { ChargerDtoV2 } from './dto/charger.dto';
import posthog from 'posthog-js';
import { useAuth } from '../../hooks/useAuth';
import { usePostHog } from 'posthog-js/react';
import { HighlightedText } from '../ui-components/highlight-text.component';
import {
  CarouselCard,
  CarouselItem,
} from '../ui-components/carousel-cards.component';
import { useUserPreference } from '../../hooks/useUserPreference';
const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '40px 24px 0px',
    height: 'calc(100vh - 192px)',
  },
  header: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
  },
  subHeader: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  dropdownWrapper: {},
  contentSection: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: '16px 36px 0px 36px',
  },
  highlightedText: {
    color: '#117DB8',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontStyle: 'semi-bold',
    fontWeight: 600,
    lineHeight: '36px',
    display: 'inline-block',
    minWidth: '40px',
  },
  featuredWrapper: {
    padding: '24px 24px 0px',
    height: 'calc(100vh - 192px)',
  },
  featuredHeader: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '36px',
    textAlign: 'center',
    color: '#202223',
    marginTop: '16px',
  },
  featuredSubHeader: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    marginTop: '4px',
  },
  featuredContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
    alignItems: 'center',
    marginTop: '16px',
  },
  featuredTimeoutText: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  featuredCounterText: {
    color: '#117DB8',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    display: 'inline-block',
    paddingBottom: '2px',
  },
  featuredTimeoutSubHeader: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: '12px auto 0px',
    width: '327px',
  },
  featuredSliderItem: {
    display: 'flex',
    width: '100%',
    padding: '12px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    borderRadius: '8px',
    background: '#FFF9EB',
  },
  featuredSliderHeader: {
    display: 'flex',
    gap: '8px',
  },
}));

const PLUGIN_TIMEOUT_SECONDS = 90;
const SINGLE_PORT_TIPS = [
  {
    icon: tipsIcon,
    title: 'tips',
    content: 'tips_content_1',
  },
] as CarouselItem[];
const MULTI_PORTS_TTPS = [
  {
    icon: tipsIcon,
    title: 'tips',
    content: 'tips_content_1',
  },
  {
    icon: tipsIcon,
    title: 'tips',
    content: 'tips_content_2',
  },
  {
    icon: tipsIcon,
    title: 'tips',
    content: 'tips_content_3',
  },
] as CarouselItem[];

interface ChargerTimeToPluginProps {
  charger: ChargerDtoV2;
  selectedConnectorId: number | null;
  sessionTimeout: () => void;
  sessionError: () => void;
  isStartSessionError?: any;
}

export const ChargerTimeToPlugin = ({
  charger,
  selectedConnectorId,
  sessionTimeout,
  sessionError,
  isStartSessionError,
}: ChargerTimeToPluginProps) => {
  const theme = useSelector(getTheme);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const posthog = usePostHog();
  const { allowSinglePortReliability, allowMultiplePortReliability } =
    useUserPreference();

  // 90 second timeout for charger plugin time
  const [timeLeft, setTimeLeft] = useState(PLUGIN_TIMEOUT_SECONDS);

  useEffect(() => {
    // Only set an interval if there's time left
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(timerId);
    } else if (timeLeft === 0) {
      sessionTimeout();
      // Capture the session timeout event using PostHog
      posthog.capture('session_timed_out', {
        method: 'charging',
        timestamp: new Date(), // Capture the timestamp of when the event occurred
      });
    } else if (
      timeLeft > 0 &&
      timeLeft < PLUGIN_TIMEOUT_SECONDS &&
      isStartSessionError
    ) {
      sessionError();
    }
  }, [timeLeft]);

  const renderNaturalUi = () => {
    return (
      <>
        <Grid container flexDirection='column' justifyContent='center'>
          <Typography
            className={classes.header}
            style={{ margin: '0px 16px 16px 16px' }}
          >
            {t('ensure_plugged_in')}
          </Typography>
          <img src={pluginCharger} alt='Unplug Logo' />
          <div style={{ margin: '16px 36px 0px 36px' }}>
            <HighlightedText
              i18nKey='session_time_out'
              values={{ timeoutSecond: timeLeft }}
              textClassName={classes.subHeader}
              highlightClassName={classes.highlightedText}
              highlightStyles={{
                color: theme.primary,
              }}
            />
          </div>
          <Typography className={classes.contentSection}>
            {t('question_help_text_desc_1')}
          </Typography>
        </Grid>
      </>
    );
  };

  const renderFeaturedUI = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={pluginCharger}
            alt='Unplug Logo'
            width='141'
            height='auto'
          />
        </div>
        <Typography className={classes.featuredHeader}>
          {t('plug_in_now')}
        </Typography>
        <Typography className={classes.featuredSubHeader}>
          {t('system_is_ready')}
        </Typography>
        <div className={classes.featuredContentWrapper}>
          <Typography className={classes.featuredTimeoutText}>
            {t('you_have_text')}
          </Typography>
          <Typography
            className={classes.featuredCounterText}
            style={{
              color: theme.primary,
            }}
          >
            {timeLeft}
          </Typography>
          <Typography className={classes.featuredTimeoutText}>
            {t('seconds_to_plug_in_text')}
          </Typography>
        </div>
        <Typography className={classes.featuredTimeoutSubHeader}>
          {t('time_out_sub_header')}
        </Typography>
      </>
    );
  };

  return (
    <>
      <div
        className={
          allowMultiplePortReliability || allowSinglePortReliability
            ? classes.featuredWrapper
            : classes.wrapper
        }
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {allowMultiplePortReliability || allowSinglePortReliability
          ? renderFeaturedUI()
          : renderNaturalUi()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '36px',
          }}
        >
          <CarouselCard
            items={
              charger.ports.length > 1 ? MULTI_PORTS_TTPS : SINGLE_PORT_TIPS
            }
            autoPlayDelay={15000}
            backgroundColor={'#FFF9EB'}
            width={'327px'}
            height={'104px'}
          />
        </div>
      </div>
      <ChargerStickyFooter
        selectedConnectorId={selectedConnectorId}
        charger={charger}
      />
    </>
  );
};
