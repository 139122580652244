import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ChargerDtoV2 } from './dto/charger.dto';
import ChargerImage from './charger-image.component';
import CustomButton from '../react-native-material-wrapper/button.component';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { useTranslation } from 'react-i18next';
import { Image } from '../ui-components/image.component';
import J1772 from '../../assets/icons/charger/J1772.svg';
import { NUMBER } from '../../constant/number.constant';
import { ColorType } from '../../assets/colors/colors.g';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    bottom: '0',
    overflow: 'hidden',
    position: 'sticky',
    background: '#FFF',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '& .leftSection': {
      gap: '10px',
      display: 'flex',
      alignSelf: 'stretch',
    },
    '& .rightSection': {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
  },
  subHeader: {
    color: '#4E5968',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '10px',
    padding: '0px 24px 16px',
  },
  buttonStyles: {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flex: '1 0 0',
    borderRadius: '4px',
    textTransform: 'none',
  },
  connectorLabel: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
  },
}));

interface ChargerStickyFooterProps {
  charger: ChargerDtoV2;
  selectedConnectorId: number | null;
  hasRetryBtn?: boolean;
  onRetry?: () => void;
  onCancel?: () => void;
}

export const ChargerStickyFooter = ({
  charger,
  selectedConnectorId,
  hasRetryBtn = false,
  onRetry,
  onCancel,
}: ChargerStickyFooterProps) => {
  const classes = useStyles();
  const theme = useSelector(getTheme);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className='leftSection'>
          <ChargerImage charger={charger} size={60} />
          <div className={classes.rightSection}>
            <Typography className={classes.header}>{charger.name}</Typography>
            <Typography className={classes.subHeader}>
              {charger?.location?.name}
            </Typography>
          </div>
        </div>
        {charger.ports.length > 1 && selectedConnectorId !== null && (
          <div className='rightSection'>
            <Image src={J1772} style={{ width: '32px', height: '32px' }} />
            {charger.ports.length === 2 && (
              <Typography className={classes.connectorLabel}>
                {+selectedConnectorId === NUMBER.ONE && t('left_port_label')}
                {+selectedConnectorId === NUMBER.TWO && t('right_port_label')}
              </Typography>
            )}
            {charger.ports.length === 3 && (
              <Typography className={classes.connectorLabel}>
                {+selectedConnectorId === NUMBER.ONE && t('left_port_label')}
                {+selectedConnectorId === NUMBER.TWO && t('middle_port_label')}
                {+selectedConnectorId === NUMBER.THREE && t('right_port_label')}
              </Typography>
            )}
          </div>
        )}
      </div>
      {hasRetryBtn && (
        <div className={classes.buttonWrapper}>
          <CustomButton
            variant='contained'
            className={classes.buttonStyles}
            style={{
              backgroundColor: theme.colorPalettes.primary,
              color: ColorType.WHITE,
            }}
            onClick={onRetry}
            disabled={false}
            disableElevation
          >
            {t('retry_btn_text')}
          </CustomButton>
          <CustomButton
            variant='contained'
            className={classes.buttonStyles}
            style={{
              background: theme.colorPalettes.light,
              color: theme.colorPalettes.primary,
            }}
            onClick={onCancel}
            disableElevation
          >
            {t('cancel')}
          </CustomButton>
        </div>
      )}
    </div>
  );
};
