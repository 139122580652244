import { useState, useMemo, useEffect } from 'react';
import CustomButton from '../react-native-material-wrapper/button.component';
import 'react-phone-input-2/lib/style.css';
import CustomTextField from '../react-native-material-wrapper/text-field.component';
import { authStyles } from './authStyles';
import { useTranslation } from 'react-i18next';
import { SUB_ROUTES } from '../../types/routes.enum';
import LoginTerms from './login-terms.component';
import { LoadingDots } from '../ui-components/loading-dots/loading-dots.component';
import { Typography } from '@mui/material';
import { ColorType } from '../../assets/colors/colors.g';
import CustomPhoneInput from '../react-native-material-wrapper/phone-input.component';

interface IProps {
  handleKeyPress: Function;
  signIn: Function;
  showRegisterInsideLogin: Function;
  setPhoneNumberOrEmailLogin: (value: string) => void;
  loading: boolean;
  theme: any;
}
const LoginForm = ({
  handleKeyPress,
  loading,
  theme,
  signIn,
  setPhoneNumberOrEmailLogin,
  showRegisterInsideLogin,
}: IProps) => {
  const [loginValue, setLoginValue] = useState('');
  const [countryCode, setCountryCode] = useState('1');
  const classes = authStyles();
  const { i18n } = useTranslation();

  const isLoginValueAPhoneNumber = useMemo(() => {
    return /^\d{2,}$/.test(loginValue);
  }, [loginValue]);

  useEffect(() => { 
    setPhoneNumberOrEmailLogin(
      isLoginValueAPhoneNumber
      ? `+${countryCode}${loginValue}`.trim()
      : loginValue,
    );
  }, [
      setPhoneNumberOrEmailLogin,
      isLoginValueAPhoneNumber,
      countryCode,
      loginValue,
  ]);

  const isIvy =
    window.domainName.toLowerCase() === SUB_ROUTES.IVY.toLowerCase();
  return (
    <div className={classes.authWrapper}>
      <Typography className={classes.loginHeader}>
        {isIvy ? i18n.t('ivy_log_in') : i18n.t('login_to_start_charging')}
      </Typography>
      <div className={classes.formInput} 
        style={{ margin: '24px 0px', 
          display: 'flex', 
          alignItems: 'center'}}>
        {isLoginValueAPhoneNumber && (
          <CustomPhoneInput
            style={{ width: '10%' }}
            id="area_code"
            onChange={(value, data) => {
              setCountryCode(data.dialCode);
            }}
            country={'ca'}
            disableCountryGuess={true}
            autoFormat={true}
            inputStyle={{
              width: 0,
              padding: 0,
              margin: 0,
              border: 'none',
            }}
            inputProps={{
              required: true,
              'aria-label': `${countryCode} selected`
            }}
          dropdownStyle={{
              background: '#F2F4F6'
            }}
          />
        )}
        <CustomTextField
          style={{ marginLeft: isLoginValueAPhoneNumber ? '8px': '0px',
            flex: 1,
          }}
          value={loginValue}
          onChange={(e) => {
            setLoginValue(e.target.value)}}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              signIn();
            }
          }}
          placeholder={i18n.t('email_address_or_phone')}
          variant='outlined'
          required
          autoFocus
          disabled={loading}
        />
      </div>
      <div
        className={classes.buttonGroup}
        style={{
          marginTop: 0,
          marginBottom: 24,
          alignItems: 'center',
        }}
      >
        <div className={classes.loginBtnWrapper}>
          <Typography className={classes.doNotHaveAccount}>
            {i18n.t('do_not_have_account')}
          </Typography>
          <CustomButton
            size='large'
            className={classes.signUpBtn}
            style={{
              color: ColorType.DENIM,
            }}
            onClick={showRegisterInsideLogin}
          >
            {i18n.t('signup_intead_text')}
          </CustomButton>
        </div>
        <CustomButton
          variant='contained'
          size='large'
          className={classes.loginBtn}
          style={{
            backgroundColor: theme.colorPalettes.primary,
          }}
          onClick={signIn}
          disabled={loading}
        >
          {!loading && i18n.t('next')}
          {loading && <LoadingDots className={classes.absolute} />}
        </CustomButton>
      </div>
      <LoginTerms />
    </div>
  );
};

export default LoginForm;
