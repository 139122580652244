import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { CSSProperties } from 'react';

interface HighlightedTextProps {
    i18nKey: string;
    values?: Record<string, string | number>;
    textClassName?: string;
    highlightClassName?: string;
    highlightStyles?: CSSProperties;
}

export const HighlightedText = ({
    i18nKey,
    values,
    textClassName,
    highlightClassName,
    highlightStyles,
}: HighlightedTextProps) => {
    return (
        <Typography
            component="div"
            className={textClassName}
        >
            <Trans
                i18nKey={i18nKey}
                values={values}
                components={{
                    highlight: (
                        <Typography
                            component="span"
                            className={highlightClassName}
                            style={highlightStyles}
                        />
                    ),
                }}
            />
        </Typography>
    );
};