export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const portsOrder = ['LEFT', 'MIDDLE', 'RIGHT'];

export const sortChargerPorts = (ports) => {
  const portArray = [...ports];

  // If 3 or fewer ports, use the location-based sorting
  if (portArray.length <= portsOrder.length) {
    return portArray?.sort((prev: any, next: any) => {
      // A port may have multi-connectors, currently we only use default one.
      const prevLocation = prev?.connectorLocations[0]?.toUpperCase();
      const nextLocation = next?.connectorLocations[0]?.toUpperCase();

      if (prevLocation && nextLocation) {
        return (
          portsOrder.indexOf(prevLocation) - portsOrder.indexOf(nextLocation)
        );
      } else if (prevLocation) {
        return -1;
      } else if (nextLocation) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  // If more than 3 ports, sort by portId numerically
  else {
    return portArray?.sort((prev: any, next: any) => {
      const prevId = parseInt(prev.portId, 10);
      const nextId = parseInt(next.portId, 10);
      return prevId - nextId;
    });
  }
};
