import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    padding: '24px',
  },
});

interface CustomDrawerIProps {
  isOpen: boolean;
  setIsOpen: Function;
  children: any;
}

function CustomDrawer(props: CustomDrawerIProps) {
  const { isOpen, setIsOpen, children } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor='bottom'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '425px',
          margin: '0 auto',
          left: '-15px',
          borderRadius: '16px 16px 0px 0px',
          background: '#FFF',
        },
      }}
    >
      <div className={classes.wrapper}>{children}</div>
    </Drawer>
  );
}

export default CustomDrawer;
