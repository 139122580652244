import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTheme } from '../../stores/selectors/theme.selector';
import { TestCompanyStatus } from '../charger/dto/charger.dto';
import { authStyles } from './authStyles';
import { ColorType } from '../../assets/colors/colors.g';
import { useCallback, useMemo } from 'react';

interface HaveQuestionsProps {
  chargerDetail: any; // Required
  createZendeskTicket?: () => any; // Optional function to create a zendesk ticket for charger support
  style?: any;
}

const HaveQuestions = ({
  chargerDetail,
  createZendeskTicket,
  style = {},
}: HaveQuestionsProps) => {
  const { i18n } = useTranslation();
  const classes = authStyles();
  const theme = useSelector(getTheme);

  const callSupport = useCallback(() => {
    if (createZendeskTicket) createZendeskTicket();
    window.location.href = `tel:${chargerDetail.contactInfo}`;
  }, [chargerDetail.contactInfo, createZendeskTicket]);

  const chargerNotBelongsToTestCompany = useMemo(() => {
    return (
      chargerDetail.testCompany === TestCompanyStatus.NOT_FROM_TEST_COMPANY ||
      !chargerDetail.testCompany
    );
  }, [chargerDetail.testCompany]);

  const whiteLabelledCharger = useMemo(() => {
    return (
      chargerDetail.whiteLabellingName === 'ChargeLab' ||
      chargerDetail.whiteLabellingName === 'Eaton'
    );
  }, [chargerDetail.whiteLabellingName]);

  const redirectHelpCenter = useCallback(() => {
    const url = chargerDetail.whiteLabellingName === 'ChargeLab'
      ? 'https://chargelab.zendesk.com/hc/en-us/sections/25563504258843-Charging-Basics'
      : 'https://eatoncharging.zendesk.com/hc/en-ca/sections/27529579005339-How-to-start-a-charging-session';
    window.open(url);
  }, [chargerDetail.whiteLabellingName]);

  const renderBasicCallSupport = useCallback(() => {
    return (
      <Typography
        variant='h3'
        style={{
          color: ColorType.DENIM,
          cursor: 'pointer',
        }}
        className={classes.typographyClass}
        onClick={callSupport}
        tabIndex={0}
      >
        {i18n.t('Call_support')}
      </Typography>
    )
  }, [callSupport, i18n.t, classes.typographyClass]);

  const renderWhiteLabelledChargerCallSupport = useCallback(() => {
    return (
      <>
        <Typography
          variant='h3'
          style={{ color: '#4E5968' }}
          className={classes.typographyClass}
        >
          {i18n.t('visit_help')}
        </Typography>
        <Typography
          variant='h3'
          style={{
            color: theme.navigationSelectedColor,
            cursor: 'pointer',
          }}
          className={classes.typographyClass}
          onClick={redirectHelpCenter}
          tabIndex={0}
        >
          {i18n.t('help_center')}
        </Typography>
        {chargerNotBelongsToTestCompany && (
          <>
            <Typography
              variant='h3'
              style={{ color: '#4E5968' }}
              className={classes.typographyClass}
            >
              {i18n.t('or')}
            </Typography>
            <Typography
              variant='h3'
              style={{
                color: ColorType.DENIM,
                cursor: 'pointer',
              }}
              className={classes.typographyClass}
              onClick={callSupport}
            >
              {i18n.t('call_support_smallcase')}
            </Typography>
          </>
        )}
      </>
    )
  }, [callSupport, i18n.t, classes.typographyClass, chargerNotBelongsToTestCompany, redirectHelpCenter]);

  if (!whiteLabelledCharger && !chargerNotBelongsToTestCompany) return null;

  return (
    <div>
      <Box className={classes.footerBox} style={{ ...style }}>
        <Typography
          variant='h3'
          style={{
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
            textAlign: 'center',
            color: '#4E5968',
          }}
        >
          {i18n.t('have_questions')}
        </Typography>
        <Box className={classes.footerDetailsBox}>
          {whiteLabelledCharger ? renderWhiteLabelledChargerCallSupport() : renderBasicCallSupport()}
        </Box>
      </Box>
    </div>
  );
};

export default HaveQuestions;
