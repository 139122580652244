import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import failedSession from '../../assets/images/charger/FailedSession.svg';
import { useTranslation } from 'react-i18next';
import { ChargerStickyFooter } from './charger-sticky-footer.component';
import { ChargerDtoV2 } from './dto/charger.dto';
import HaveQuestions from '../login/have-questions.component';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '40px 24px 0px',
    height: 'calc(100vh - 192px)',
  },
  header: {
    color: '#202223',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
  },
  subHeader: {
    color: '#4E5968',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  dropdownWrapper: {},
}));

interface SessionFailedProps {
  charger: ChargerDtoV2;
  selectedConnectorId: number | null;
  onRetry: any;
  onCancel: () => void;
}

export const SessionFailed = ({
  charger,
  selectedConnectorId,
  onRetry,
  onCancel,
}: SessionFailedProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <Grid container flexDirection='column' justifyContent='center' alignItems='center'>
          <img src={failedSession} alt='Session Failed' width={240} height={240} />
          <Typography className={classes.header} style={{ marginTop: 24 }}>
            {t('session_failed_header')}
          </Typography>
          <Typography className={classes.subHeader} style={{ margin: '8px 8px 0px 8px' }}>
            {t('session_failed_instruction')}
          </Typography>
          <HaveQuestions chargerDetail={charger} style={{ width: '327px', margin: '24px 0px 0px 0px' }} />
        </Grid>
      </div>
      <ChargerStickyFooter
        hasRetryBtn
        onRetry={onRetry}
        onCancel={onCancel}
        charger={charger}
        selectedConnectorId={selectedConnectorId}
      />
    </>
  );
};
