import {
    Typography,
    MobileStepper,
    Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';

export interface CarouselItem {
    icon?: string;
    title: string;
    content: string;
}

interface CarouselCardProps {
    items: CarouselItem[];
    autoPlayDelay?: number;
    className?: string;
    backgroundColor?: string;
    width?: number | string;
    height?: number | string;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
    },
    card: {
        padding: '16px',
        borderRadius: '8px',
        width: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginBottom: '8px',
    },
    icon: {
        width: '24px',
        height: '24px',
    },
    title: {
        color: '#202223',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
        fontStyle: 'semi-bold',
    },
    content: {
        color: '#4E5968',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        fontStyle: 'medium',
    },
    stepper: {
        backgroundColor: 'transparent',
        padding: 0,
        width: 'auto',
        '& .MuiMobileStepper-dot': {
            width: '8px',
            height: '8px',
            margin: '0 4px',
            backgroundColor: '#D1D6DB',
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: '#B0B8C1',
        },
    },
    cardContent: {
        userSelect: 'none',
        '& *': {
            userSelect: 'none',
            pointerEvents: 'none',
        }
    },
}));

export const CarouselCard = ({
    items,
    autoPlayDelay = 3000,
    className,
    backgroundColor = '#F2F4F6',
    width,
    height,
}: CarouselCardProps) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const { t } = useTranslation();

    const handleChangeIndex = (index: number) => {
        setActiveStep(index);
    };

    useEffect(() => {
        if (items.length <= 1) return;

        const timer = setInterval(() => {
            setActiveStep((prevStep) =>
                prevStep === items.length - 1 ? 0 : prevStep + 1
            );
        }, autoPlayDelay);

        return () => clearInterval(timer);
    }, [items.length, autoPlayDelay]);

    return (
        <Box
            className={classes.wrapper}
            sx={{ width: width || '100%' }}
        >
            <div
                key={'abc'}
                // className={`${classes.card} ${className}`}
                style={{
                    backgroundColor,
                    width: width || '100%',
                    height: height,
                    overflow: 'hidden',
                }}
            >
                <SwipeableViews
                    enableMouseEvents
                    index={activeStep}
                    onChangeIndex={handleChangeIndex}
                    resistance
                    disabled={items.length <= 1}
                    style={{
                        margin: '8px 16px 8px 16px',
                        gap: '8px',
                    }}
                >
                    {items.map((item, index) => (
                        <Box key={index} className={classes.cardContent}>
                            <Box className={classes.header}>
                                {item.icon && (
                                    <img
                                        src={item.icon}
                                        alt={t(item.title)}
                                        className={classes.icon}
                                    />
                                )}
                                <Typography className={classes.title}>
                                    {t(item.title)}
                                </Typography>
                            </Box>
                            <Typography className={classes.content}>
                                {t(item.content)}
                            </Typography>
                        </Box>
                    ))}
                </SwipeableViews>
            </div>

            {items.length > 1 && (
                <MobileStepper
                    steps={items.length}
                    position="static"
                    activeStep={activeStep}
                    className={classes.stepper}
                    nextButton={null}
                    backButton={null}
                />
            )}
        </Box>
    );
};