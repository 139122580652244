import { useMemo, useState, useEffect } from 'react';
import appIcon from '../../assets/images/chargelab/app-icon.svg';
import { ColorType } from '../../assets/colors/colors.g';
import { useDomain } from '../../hooks/useDomain';
import {
  Box,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

const AppBanner = ({ onClose }: { onClose: () => void }) => {
  const APP_DOWNLOAD_EVENT = 'app_universal_banner_redirect_click_continue';
  const { domain } = useDomain();
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [appId, setAppId] = useState<string | null>(null);

  const { platform, countryCode } = useMemo(() => {
    const userAgent = navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    const countryCode = navigator.language.split('-')[1]?.toUpperCase();
    return {
      platform: isIOS ? 'ios' : /android/i.test(userAgent) ? 'android' : undefined,
      countryCode
    };
  }, []);

  useEffect(() => {

    const getAppId = () => {
      switch (countryCode) {
        case 'CA':
          return '1566145524';
        case 'US':
          return '6472620020';
        default:
          return null;
      }
    };

    setAppId(getAppId());

  }, [countryCode]);

  const appOrPlayStoreUrl = platform === 'ios' ? `https://apps.apple.com/app/id${appId}` : `https://play.google.com/store/apps/details?id=co.chargelab`;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const allowedDomains = ['chargelab'];

  // Check if banner should be displayed
  if (!isMobile ||
    !allowedDomains.includes(domain) ||
    !appId) {
    return null;
  }

  const handleButtonClick = () => {
    // Track the click event
    posthog.capture(APP_DOWNLOAD_EVENT, {
      method: 'app_download',
      platform: platform,
      countryCode: countryCode,
      timestamp: new Date()
    });

    // Navigate to app store
    window.location.href = appOrPlayStoreUrl;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: '#F2F4F6',
        position: 'relative',
        width: '100%',
        height: '79px',
        p: '5px 10px 5px 10px',
        boxSizing: 'border-box',
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          p: '8px',
          color: '#666666'
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Box
        component="img"
        src={appIcon}
        alt="ChargeLab App Icon"
        sx={{
          width: '42px',
          height: '42px',
          borderRadius: '8px',
          mr: '12px'
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#000' }}>
          {t('app_banner_title')}
        </Typography>
      </Box>
      <Button
        onClick={handleButtonClick}
        sx={{
          width: '86px',
          height: '32px',
          fontWeight: 500,
          bgcolor: ColorType.BRAND3,
          color: '#FFFFFF',
          fontSize: '14px',
          mr: '8px',
          borderRadius: '4px',
          textTransform: 'none',
          '&:hover': {
            bgcolor: ColorType.BRAND3,
          }
        }}
      >
        {t('app_banner_button')}
      </Button>
    </Box>
  );

  return null;
};

export default AppBanner; 
